<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-6">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">{{ lista_campos != '' ? 'Editar' : 'Criar' }} Forma de Pagamento</h3>
        </div>
        <div class="card-body">
          <form action="">
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">Nome:*</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.nome"
                    placeholder="Digite o nome..."
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">Descrição:*</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.descricao"
                    placeholder="Digite o nome..."
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label class="col-md-12">Quantidade de Parcelas:*</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.qtdparc"
                    placeholder="Digite o nome..."
                />
              </div>
              <div class="col-md-6">
                <label class="col-md-12">Selecione modo de Pagamento :*</label>
                <select
                    class="form-control"
                    v-model="form.finan_modo_pagamento_id"
                    name=""
                    id=""
                >
                  <option
                      v-for="(lista, index) in lista_finan_modo_pagamentos"
                      :key="index"
                      :value="lista.id"
                  >
                    {{ lista.nome }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                    @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')"
                    class="btn btn-primary"
                    :disabled="verif"
                >
                  Salvar
                  <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {fireAlert} from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        nome: "",
        descricao: "",
        finan_modo_pagamento_id: "",
        qtdparc: "",
        status: 1,
      },
      verif: false,
    };
  },
  created() {
    this.preenxerCampos();
    this.listar_requerimentos();
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.financeiro.mensagem_alert;
    },
    lista_finan_modo_pagamentos() {
      return this.$store.state.financeiro.lista_finan_modo_pagamentos;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + tipo + ` uma forma de pagamento no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("financeiro/create_finan_forma_pagamento", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'formaPagamento'
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("financeiro/update_finan_forma_pagamento", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'formaPagamento'
      });
    },
    preenxerCampos() {
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          status: this.lista_campos.status,
          descricao: this.lista_campos.descricao,
          qtdparc: this.lista_campos.qtdparc,
          finan_modo_pagamento_id: this.lista_campos.finan_modo_pagamento_id,

        };
    },
    listar_requerimentos() {
      this.$store.dispatch("financeiro/listar_finan_modo_pagamento");
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>